import { Navigate } from "react-router-dom";
//Dashboard

//login
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// User Profile
import Media from "pages/Media/Media";
import Profile from "pages/User/Profile";

import CmsHubMedia from "pages/CmsHub";
import CmsHubMembership from "pages/Membership";
//
import Account from "pages/Account";
import CreateAndUpdateAccount from "pages/Account/CreateAndUpdate";
import SetNewPassword from "pages/Authentication/SetNewPassword";
import Revenue from "pages/Media/Revenue";
import ArticleStatistics from "pages/ArticleManagement/ArticleStatistics";
import Statistics from "pages/CmsHub/Statistics";
import UsageExceeded from "pages/Membership/UsageExceeded";
import ArticleList from "pages/ArticleManagement/ArticleList";
import InquiryList from "pages/Inquiry";
import InquiryHomePage from "pages/Inquiry/HomePage";
import MediaDeleteMember from "pages/Media/MediaDeleteMember";
import ComingSoon from "pages/Pages/ComingSoon/ComingSoon";
import Import from "pages/Import";
import TransferList from "pages/Transfer";
import Announcement from "pages/Announcement";
import CustomerInquiryDetail from "pages/Inquiry/CustomerInquiryDetail";
import ServiceInquiryDetail from "pages/Inquiry/ServiceInquiryDetail";
import HomePageInquiryDetail from "pages/Inquiry/HomePageInquiryDetail";


const ROUTES = {
  DASHBOARD: "/dashboard",
  PROFILE: "/profile",

  MEDIA: "/media",
  MEDIA_REVENUE: "/revenue",
  MEDIA_DELETE_MEMBER: "/media/delete",
  REVENUE: "/revenue",
  //
  CMS_HUB_MEDIA_MANAGEMENT: "/presscon-company",
  CMS_HUB_MEMBERSHIP: "/presscon-membership",
  CMS_HUB_MEMBERSHIP_USAGE_EXCEEDED: "/presscon-membership/usage-exceeded",
  CMS_HUB_STATISTICS: '/presscon/statistics',
  //

  ACCOUNT: "/account",
  ACCOUNT_CREATE: "/account/create",
  ACCOUNT_UPDATE: "/account/update",
  SET_NEW_PASSWORD: "/set-new-password",

  INQUIRY_MANAGEMENT: {
    INQUIRY: '/inquiry',
    INQUIRY_HOMEPAGE: '/inquiry/homepage',
    INQUIRY_DETAIL: '/inquiry/:id',
    INQUIRY_SERVICE_DETAIL: '/inquiry/service/:id',
    INQUIRY_HOMEPAGE_DETAIL: '/inquiry/homepage/:id'
  },
  TRANSFER_MANAGEMENT: "/transfer",
  // Articles Management
  ARTICLES_MANAGEMENT: {
    ARTICLE_STATISTICS: "/articles-management/article-statistics",
    ARTICLE_LIST: "/articles-management/article-list",
  },
  IMPORT:"/import",
  ANNOUNCEMENT_MANAGEMENT: "/announcement-management",
}

const authProtectedRoutes = [
  { path: ROUTES.MEDIA, component: <Media /> },

  { path: ROUTES.MEDIA_REVENUE, component: <Revenue /> },

  { path: ROUTES.MEDIA_DELETE_MEMBER, component: <MediaDeleteMember /> },

  { path: ROUTES.PROFILE, component: <Profile /> },

  // CMS-HUB
  { path: ROUTES.CMS_HUB_MEDIA_MANAGEMENT, component: <CmsHubMedia /> },

  { path: ROUTES.CMS_HUB_STATISTICS, component: <Statistics /> },

  { path: ROUTES.ACCOUNT, component: <Account /> },

  { path: ROUTES.ACCOUNT_CREATE, component: <CreateAndUpdateAccount /> },

  { path: ROUTES.ACCOUNT_UPDATE, component: <CreateAndUpdateAccount /> },

  { path: ROUTES.CMS_HUB_MEMBERSHIP, component: <CmsHubMembership /> },
  { path: ROUTES.CMS_HUB_MEMBERSHIP_USAGE_EXCEEDED, component: <UsageExceeded /> },
  // Articles-Management
  {
    path: ROUTES.ARTICLES_MANAGEMENT.ARTICLE_STATISTICS,
    component: <ArticleList />,
  },
  {
    path: ROUTES.ARTICLES_MANAGEMENT.ARTICLE_LIST,
    component: <ArticleStatistics />,
  },
  { path: ROUTES.INQUIRY_MANAGEMENT.INQUIRY, component: <InquiryList /> },
  { path: ROUTES.INQUIRY_MANAGEMENT.INQUIRY_HOMEPAGE, component: <InquiryHomePage /> },
  { path: ROUTES.INQUIRY_MANAGEMENT.INQUIRY_DETAIL, component: <CustomerInquiryDetail /> },
  { path: ROUTES.INQUIRY_MANAGEMENT.INQUIRY_SERVICE_DETAIL, component: <ServiceInquiryDetail /> },
  { path: ROUTES.INQUIRY_MANAGEMENT.INQUIRY_HOMEPAGE_DETAIL, component: <HomePageInquiryDetail /> },

  { path: ROUTES.TRANSFER_MANAGEMENT, component: <TransferList /> },
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/media" />,
  },
  {
    path:ROUTES.IMPORT,
    exact: true,
    component: <Import />,
  },
  {
    path:ROUTES.ANNOUNCEMENT_MANAGEMENT,
    exact: true,
    component: <Announcement />,
  },
  { path: "*", component: <Navigate to="/media" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
  { path: ROUTES.SET_NEW_PASSWORD, component: <SetNewPassword /> },

  //AuthenticationInner pages
  { path: "/pages-coming-soon", component: <ComingSoon /> },
];

export { authProtectedRoutes, publicRoutes, ROUTES };
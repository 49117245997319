import { APIClient } from "./axiosClient";

import { QueryParams } from "components/Hooks/useQueryParams";
const api = new APIClient();

const path = "/announcements";

const announcementApi = {
  getListAnnouncement(dataForm: QueryParams) {
    const url = `${path}`;
    return api.get(url, dataForm);
  },
  createAnnouncements(body: any) {
    const url = `${path}`;
    return api.post(url, body, {
      headers:{
        'Content-Type':  'multipart/form-data',
        'x-ms-blob-type': "BlockBlob"
      }
    });
  },
  updateAnnouncements(id:number,body: any) {
    const url = `${path}/${id}`;
    return api.put(url, body, {
      headers:{
        'Content-Type':  'multipart/form-data',
        'x-ms-blob-type': "BlockBlob"
      }
    });
  },
  deleteAnnouncements(ids:Array<number>) {
    const url = `${path}`;
    return api.delete(url,{data:{ids}});
  },
};
export default announcementApi;

import { useFormik } from "formik";
import moment from "moment";
import DateTimePickerCustom from "pages/CmsHub/Components/DateTimePicker";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { FormFeedback, Input, Label, Modal, ModalBody, Spinner } from "reactstrap";
import * as Yup from "yup";
//
import { ANNOUNCEMENT_EXPOSURE_TYPE, ANNOUNCEMENT_TYPE } from "helpers/constans";
import { catchAsync } from "helpers/general";
import announcementApi from "api/announcementApi";

interface Props {
    open: boolean;
    data?: any;
    onSubmit?: () => void;
    onClose?: () => void;
}

interface Data {
    title: string;
    exposure_type: number;
    type: number;
    is_urgent: number;
    content: string;
}

function ModalAnnouncement({ open, data, onSubmit, onClose }: Props) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const inputFileRef = useRef<any>(null);
    const [files, setFiles] = useState<{ file: any; path: string; link: string }[]>([]);
    const initialValues = {
        title: "",
        exposure_type: ANNOUNCEMENT_EXPOSURE_TYPE.EXPOSURE,
        type: ANNOUNCEMENT_TYPE.NOTICE,
        is_urgent: 0,
        content: "",
        exposure_start_date: "",
        exposure_end_date: "",
    };

    const popupSchema = Yup.object().shape({
        title: Yup.string().required(),
        exposure_type: Yup.number(),
        type: Yup.number(),
        is_urgent: Yup.number(),
        content: Yup.string().max(3000).required(),
        exposure_start_date: Yup.string().when("exposure_type", (exposure_type: any, schema) => {
            return exposure_type[0] === ANNOUNCEMENT_EXPOSURE_TYPE.PERIOD_EXPOSURE
                ? schema.required()
                : schema.optional();
        }),
        exposure_end_date: Yup.string().when("exposure_type", (exposure_type: any, schema) => {
            return exposure_type[0] === ANNOUNCEMENT_EXPOSURE_TYPE.PERIOD_EXPOSURE
                ? schema.required()
                : schema.optional();
        }),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: popupSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            if (values.exposure_type !== ANNOUNCEMENT_EXPOSURE_TYPE.PERIOD_EXPOSURE) {
                values.exposure_start_date = "";
                values.exposure_end_date = "";
            };
            const formData = new FormData();
            (Object.keys(values) as (keyof Data)[]).forEach(key => {
                formData.append(key, values[key].toString());
            });
            const fileOld = files?.filter((item) => !item?.file);
            formData.append(`attack_files`, fileOld?.length ? JSON.stringify(fileOld) : "[]");
            const fileUploadNew = files?.filter((item) => item?.file);
            if(fileUploadNew?.length){
                fileUploadNew.forEach((item, index) => {
                    formData.append(`files[${index}]`, item.file);
                    formData.append(`links[${index}]`, item.link);
                });
            };
            const callApi = data?.id ? announcementApi.updateAnnouncements(data?.id,formData) : announcementApi.createAnnouncements(formData);
            const [error, result] = await catchAsync(
                callApi, setLoading
            );

            if (error) {
                typeof error === "string"
                    ? toast.error(error)
                    : formik.setErrors(error);
                return;
            }
            toast.success(result.message);
            formik.resetForm();
            onSubmit && onSubmit();
            return;
        },
    });

    const statusPopup = [
        {
            label: t("announcement.exposure"),
            key: ANNOUNCEMENT_EXPOSURE_TYPE.EXPOSURE,
        },
        {
            label: t("announcement.period_exposure"),
            key: ANNOUNCEMENT_EXPOSURE_TYPE.PERIOD_EXPOSURE,
        },
        {
            label: t("announcement.un_exposed"),
            key: ANNOUNCEMENT_EXPOSURE_TYPE.NON_EXPOSURE,
        },
    ];

    const categories = [
        {
            label: t("announcement.announcement"),
            key: ANNOUNCEMENT_TYPE.NOTICE,
        },
        {
            label: t("announcement.update"),
            key: ANNOUNCEMENT_TYPE.UPDATE,
        },
    ];
    const handleButtonClick = () => {
        inputFileRef.current?.click();
    };

    const handleUpload = (event: { target: { value: string; files: any } }) => {
        const selectedFiles = event.target.files;
        if (!selectedFiles) return;
    
        setFiles((prevFiles) => {
            const remainingSlots = 5 - prevFiles.length;
            if (remainingSlots <= 0) return prevFiles;
    
            const validFiles: { file: any; path: string; link: string }[] = [];
            for (let i = 0; i < selectedFiles.length && validFiles.length < remainingSlots; i++) {
                const file = selectedFiles[i];
                if (!['image/png', 'image/jpeg'].includes(file.type)) continue;
                const preview = URL.createObjectURL(file);
                validFiles.push({ file, path: preview, link: "" });
            }
    
            return [...prevFiles, ...validFiles];
        });
    
        setTimeout(() => {
            event.target.value = "";
        }, 0);
    };

    const handleLinkChange = (index: number, value: string) => {
        setFiles((prevFiles) => {
            const updatedFiles = [...prevFiles];
            updatedFiles[index].link = value;
            return updatedFiles;
        });
    };


    const handleRemoveFile = (indexToRemove: number) => {
        setFiles((prevFiles) =>
            prevFiles.filter((_, index) => index !== indexToRemove)
        );
    };

    useEffect(() => {
       if(!open){
        setFiles([]);
        formik.resetForm();
       } else {
        formik.setValues({
            title: data?.title || "",
            exposure_type: data?.exposure_type || ANNOUNCEMENT_EXPOSURE_TYPE.EXPOSURE,
            type: data?.type || ANNOUNCEMENT_TYPE.NOTICE,
            is_urgent: (data?.is_urgent ? 1 : 0) || 0,
            content: data?.content || "",
            exposure_start_date: data?.exposure_start_date || "",
            exposure_end_date: data?.exposure_end_date || "",
        });
        setFiles(data?.attach_file || [])
       }
    },[open, data]);

    return (
        <Modal
            isOpen={open}
            centered={true}
            scrollable={false}
            style={{ maxWidth: "471px", width: "100%" }}
        >
            <ModalBody className=''>
                <div>
                    <div className='d-relative'>
                        <h5 className='text-center w-100 mb-4'>{ data?.id ? t("announcement.update_notice") : t("announcement.register_notice")}</h5>
                    </div>
                    <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                        <div className="form-group">
                            <label className="col-form-label">
                                {t("announcement.title")}
                                <span className="text-danger"> *</span>
                            </label>
                            <Input
                                type="text"
                                className="form-control"
                                {...formik.getFieldProps("title")}
                                placeholder={t(
                                    "announcement.please_enter_a_title"
                                )}
                                maxLength={50}
                            />
                            <FormFeedback>
                                {formik?.values?.title ? formik?.errors?.title : ""}
                            </FormFeedback>
                        </div>
                        <div className="form-group">
                            <label className="col-form-label">
                                {t("announcement.popup_display_settings")}
                                <span className="text-danger"> *</span>
                            </label>
                            <div className="d-flex gap-4">
                                {statusPopup?.map((item, index: number) => (
                                    <div
                                        key={item?.key}
                                        className="cursor-pointer table-hover-td-content-preview"
                                        onClick={() => {
                                            formik.setFieldValue("exposure_type", item?.key);
                                        }}
                                    >
                                        <div>
                                            <div
                                                className="d-flex justify-content-between align-items-center form-check"
                                                style={{
                                                    verticalAlign: "middle",
                                                    display: "inline-flex",
                                                }}
                                            >
                                                <div className="d-flex justify-content-start">
                                                    <input
                                                        className="form-check-input me-2"
                                                        type="radio"
                                                        name="exposure_type"
                                                        id={`exposure_type${index}`}
                                                        checked={item?.key === formik.getFieldProps("exposure_type").value}
                                                        style={{ minWidth: "16px" }}
                                                    />
                                                    <label
                                                        className="form-check-label position-relative"
                                                        htmlFor={`exposure_type${index}`}
                                                    >
                                                        {item?.label}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <FormFeedback>
                                {formik?.values?.exposure_type
                                    ? formik?.errors?.exposure_type
                                    : ""}
                            </FormFeedback>
                        </div>
                        {
                            formik?.values?.exposure_type === ANNOUNCEMENT_EXPOSURE_TYPE.PERIOD_EXPOSURE ? <div className="d-flex gap-3 mt-2 form-group">
                                <div className="w-100">
                                    <Label
                                        htmlFor="exposure_start_date"
                                        className="form-label"
                                        style={{ fontSize: '12px' }}
                                    >
                                        {t("announcement.start_date")}
                                    </Label>
                                    <DateTimePickerCustom
                                        className="w-100"
                                        wrapperClassName="w-100"
                                        placeholder="----:--:-- --:--"
                                        dateFormat="yyyy-MM-dd HH:mm"
                                        date={formik.values?.exposure_start_date}
                                        onChange={(date) => {
                                            formik.setFieldValue("exposure_start_date", date);
                                            if (
                                                moment(date) > moment(formik?.values?.exposure_end_date)
                                            ) {
                                                formik.setFieldValue("exposure_end_date", null);
                                                return;
                                            }
                                        }}
                                    />
                                </div>
                                <div className="w-100">
                                    <Label
                                        htmlFor="end_date"
                                        className="form-label"
                                        style={{ fontSize: '12px' }}
                                    >
                                        {t("announcement.end_date")}
                                    </Label>
                                    <DateTimePickerCustom
                                        className="w-100"
                                        wrapperClassName="w-100"
                                        dateFormat="yyyy-MM-dd HH:mm"
                                        placeholder="----:--:-- --:--"
                                        date={formik?.values?.exposure_end_date}
                                        onChange={(date) => {
                                            formik.setFieldValue("exposure_end_date", date);
                                        }}
                                        minDate={formik?.values?.exposure_start_date}
                                    />
                                </div>
                            </div> : null
                        }
                        <div className="form-group">
                            <label className="col-form-label">
                                {t("announcement.category")}
                                <span className="text-danger"> *</span>
                            </label>
                            <div className="d-flex gap-4">
                                {categories?.map((item, index: number) => (
                                    <div
                                        key={item?.key}
                                        className="cursor-pointer table-hover-td-content-preview"
                                        onClick={() => {
                                            formik.setFieldValue("type", item?.key);
                                        }}
                                    >
                                        <div>
                                            <div
                                                className="d-flex justify-content-between align-items-center form-check"
                                                style={{
                                                    verticalAlign: "middle",
                                                    display: "inline-flex",
                                                }}
                                            >
                                                <div className="d-flex justify-content-start">
                                                    <input
                                                        className="form-check-input me-2"
                                                        type="radio"
                                                        name="flexRadioDefault"
                                                        id={`flexRadioDefault${index}`}
                                                        checked={
                                                            item?.key ===
                                                            formik.getFieldProps("type").value
                                                        }
                                                        style={{ minWidth: "16px" }}
                                                    />
                                                    <label
                                                        className="form-check-label position-relative"
                                                        htmlFor={`flexRadioDefault${index}`}
                                                    >
                                                        {item?.label}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-form-label">
                                {t("announcement.urgency_status")}
                            </label>
                            <div className="form-check form-switch form-switch-primary notification-check mb-2">
                                <input className="form-check-input" type="checkbox" role="switch" id={`check-all`} checked={formik.getFieldProps("is_urgent").value} onChange={(e) => formik.setFieldValue("is_urgent", e.target.checked ? 1 : 0)} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-form-label">
                                {t("announcement.detail")}
                                <span className="text-danger"> *</span>
                            </label>
                            <div>
                                <textarea
                                    className="form-control"
                                    id="textMemo"
                                    rows={5}
                                    placeholder={t("announcement.please_enter_detail")}
                                    {...formik.getFieldProps("content")}
                                    maxLength={3000}
                                />
                                <div className="text-right mt-1">{formik?.values?.content?.length}/3000</div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center gap-4">
                            <div>{t('announcement.attachment')}</div>
                            <button
                                type="button"
                                className="btn btn-primary fs-14"
                                style={{ width: "150px" }}
                                onClick={handleButtonClick}
                                disabled={files?.length >= 5}
                            >
                                {t("announcement.attach_file")}
                            </button>
                            <input
                                type="file"
                                ref={inputFileRef}
                                style={{ display: "none" }}
                                onChange={handleUpload}
                                accept="image/png, image/jpeg"
                                multiple
                                disabled={files?.length >= 5}
                            />
                        </div>
                        <div>
                            {files.length > 0 && (
                                <div className="preview d-flex flex-column gap-2 mt-3 w-100">
                                    {files.map((item, index) => (
                                        <div key={index} className="d-flex gap-3 align-items-center w-100">
                                            <img
                                                src={item.path}
                                                alt={`preview-${index}`}
                                                style={{ minWidth:'66px', width: "66px", height: "66px", borderRadius: "6px", objectFit: "contain", background: '#D9D9D9' }}
                                            />
                                            <div className="d-flex flex-column gap-1 w-100">
                                                <div>링크주소</div>
                                                <input type="text" placeholder={t('announcement.pls_enter_link_address')} className="w-100" style={{ padding: '8px 10px', border: '1px solid #D9D9D9', borderRadius: '4px' }} value={item.link}
                                                    onChange={(e) => handleLinkChange(index, e.target.value)} />
                                            </div>
                                            <button
                                                type="button"
                                                className="btn btn-soft-danger"
                                                onClick={() => handleRemoveFile(index)}
                                            >
                                                <i className='bx bx-trash-alt' style={{ fontSize: 20 }}></i>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="hstack gap-2 mt-5 justify-content-center">
                            <button
                                className="btn btn-soft-secondary fs-14"
                                type="button"
                                color="light"
                                onClick={() => onClose && onClose()}
                                style={{ width: "150px" }}
                            >
                                {t("cms_media.cancellation")}
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary fs-14"
                                style={{ width: "150px" }}
                                disabled={!formik.isValid || !formik.dirty}
                            >
                                {loading ? <Spinner size="sm me-2"></Spinner> : null}
                                {t("announcement.save")}
                            </button>
                        </div>
                    </form>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default ModalAnnouncement;

/* eslint-disable react-hooks/exhaustive-deps */
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Input,
    Nav,
    NavItem,
    NavLink,
    Row,
} from "reactstrap";

import {
    ANNOUNCEMENT_EXPOSURE_TYPE,
    ANNOUNCEMENT_TYPE,
} from "helpers/constans";
import moment from "moment";
import classnames from "classnames";
//
import useQueryParams from "components/Hooks/useQueryParams";
import { catchAsync } from "helpers/general";
import { IPagination } from "api/types/general";
import TableContainer from "components/Common/TableContainer";
import DatePickerCustom from "components/Common/DatePickerCustom";
import "yet-another-react-lightbox/styles.css";
import BreadCrumb from "components/Common/BreadCrumb";
import ModalAnnouncement from "./ModalAunoucement";
import announcementApi from "api/announcementApi";
import { useModal } from "context/confirm.context";

interface Option {
    label: string;
    value: string;
}

export interface Tag {
    id: string;
    text: string;
}

registerLocale("en", en);
registerLocale("ko", ko);

const AnnouncementList = () => {
    const { showModal } = useModal();
    const { t, i18n } = useTranslation();
    const location = useLocation();

    const SERVICE_TYPE_OPTIONS_LANG = [
        {
            label: t("All Type"),
            value: "0",
        },
        {
            label: t("announcement.exposure"),
            value: ANNOUNCEMENT_EXPOSURE_TYPE.EXPOSURE?.toString(),
        },
        {
            label: t("announcement.period_exposure"),
            value: ANNOUNCEMENT_EXPOSURE_TYPE.PERIOD_EXPOSURE?.toString(),
        },
        {
            label: t("announcement.end"),
            value: ANNOUNCEMENT_EXPOSURE_TYPE.NON_EXPOSURE?.toString(),
        },
    ]

    const [formatSearch, setFormatSearch] = useState<Option | null>({
        label: t("All Type"),
        value: "0",
    });

    const ANNOUNCEMENT_TYPE_LABEL = {
        [ANNOUNCEMENT_TYPE.NOTICE]: t('announcement.announcement'),
        [ANNOUNCEMENT_TYPE.UPDATE]: t('announcement.update'),
    };

    const ANNOUNCEMENT_EXPOSURE_TYPE_LABEL = {
        [ANNOUNCEMENT_EXPOSURE_TYPE.EXPOSURE]: t('announcement.exposure'),
        [ANNOUNCEMENT_EXPOSURE_TYPE.PERIOD_EXPOSURE]: t('announcement.period_exposure'),
        [ANNOUNCEMENT_EXPOSURE_TYPE.NON_EXPOSURE]: t('announcement.end'),
    };

    const [loading, setLoading] = useState(false);
    const defaultParams = {
        page: 1,
        page_size: 10,
        search: "",
        start_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
    };
    const { getQueryParams, clearQueryParams, setQueryParams } = useQueryParams(defaultParams);
    const queryParams = getQueryParams();
    const [keyword, setKeyword] = useState<string>("");
    const [rows, setRows] = useState<Array<any>>([]);
    const [pagination, setPagination] = useState({} as IPagination);
    const defaultRangeDate = [
        queryParams?.start_date ? moment(queryParams?.start_date || "", "Y-MM-DD").toDate() : null,
        queryParams?.end_date ? moment(queryParams?.end_date || "", "Y-MM-DD").toDate() : null,
    ];
    const [dateSearch, setDateSearch] = useState<string[]>(defaultRangeDate);
    const [startDate, endDate] = dateSearch;
    const [isChangeKeyword, setIsChangeKeyword] = useState<boolean>(false);
    const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
    const [detail, setDetail] = useState<any>(null);
    const [selectedIds, setSelectedIds] = useState<Array<number>>([]);
    const [selectAll, setSelectAll] = useState(false);

    const columns = useMemo(
        () => [
            {
                Header: <><input
                    className="form-check-input"
                    type="checkbox"
                    onChange={(e) => {
                        handleSelectAll(e)
                    }}
                    checked={selectAll}
                /></>,
                accessor: "id",
                thClass: "text-center",
                Cell: (cell: any) => (
                    <input
                        className="form-check-input"
                        type="checkbox"
                        onClick={(e) => e.stopPropagation()}
                        checked={selectedIds.includes(cell?.row?.original?.id)}
                        onChange={() => handleSelectItem(cell?.row?.original?.id)}
                    />
                ),
            },
            {
                Header: t("inquiry.STT"),
                thClass: "text-center",
                Cell: (cell: any) => (
                    <div
                        className='text-center'
                    >
                        <span>
                            {pagination.total - pagination.page_size * (pagination.page - 1) - cell?.row?.index}
                        </span>
                    </div>
                ),
            },
            {
                Header: t("announcement.popup_display_settings"),
                accessor: "exposure_type",
                thClass: "text-center",
                Cell: (cell: any) => (
                    <div>
                        {ANNOUNCEMENT_EXPOSURE_TYPE_LABEL[Number(cell?.value)] || "-"}
                    </div>
                ),
            },
            {
                Header: t("announcement.category"),
                accessor: "type",
                thClass: "text-center",
                Cell: (cell: any) => (
                    <div>
                        {ANNOUNCEMENT_TYPE_LABEL[Number(cell?.value)] || "-"}
                    </div>
                ),
            },
            {
                Header: t("announcement.title"),
                accessor: "title",
                thClass: "text-center",
                Cell: (cell: any) => (
                    <div>
                        {cell?.value || "-"}
                    </div>
                ),
            },
            {
                Header: t("announcement.registration_date"),
                accessor: "created_at",
                thClass: "text-center",
                Cell: (cell: any) => (
                    <div
                        className='mb-0'
                        style={{
                            minWidth: "150px",
                        }}
                    >
                        {moment(cell.value).isValid() ? moment(cell.value).format("YYYY.MM.DD HH:mm") : "-"}
                    </div>
                ),
            },
            {
                Header: t("announcement.exposure_date"),
                accessor: "exposure_date",
                thClass: "text-center",
                Cell: (cell: any) => {
                    let exposureStart = cell?.row?.original?.exposure_start_date;
                    const exposureEnd = cell?.row?.original?.exposure_end_date;
                    let displayDate = "";
                    if(cell?.row?.original?.exposure_type === ANNOUNCEMENT_EXPOSURE_TYPE.EXPOSURE){
                        exposureStart = cell?.row?.original?.created_at;
                    }
                    if (exposureStart && exposureEnd) {
                        displayDate = [
                            moment(exposureStart).format("YYYY.MM.DD HH:mm"),
                            moment(exposureEnd).format("YYYY.MM.DD HH:mm")
                        ].join(" ~ ");
                    } else if (exposureStart) {
                        displayDate = moment(exposureStart).format("YYYY.MM.DD HH:mm") + " ~";
                    } else if (exposureEnd) {
                        displayDate = moment(exposureEnd).format("YYYY.MM.DD HH:mm");
                    } else {
                        displayDate = "-";
                    }
                    return <div
                        className='mb-0'
                    >
                        {
                            displayDate
                        }
                    </div>
                },
            },
            {
                Header: t("announcement.urgency_status"),
                accessor: "is_urgent",
                thClass: "text-center",
                Cell: (cell: any) => (
                    <div>
                        {cell?.value ? 'Y' : "N"}
                    </div>
                ),
            },
        ],
        [i18n?.language, pagination, selectedIds, selectAll]
    );

    const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
        setSelectAll(e.target.checked);
        setSelectedIds(e.target.checked ? rows.map((item) => item.id) : []);
    };

    const handleSelectItem = (id: number) => {
        if (selectedIds.includes(id)) {
            setSelectedIds(selectedIds.filter((item) => item !== id));
        } else {
            setSelectedIds([...selectedIds, id]);
        }
    };

    const searchData = () => {
        setQueryParams({
            ...queryParams,
            page: 1,
            search: isChangeKeyword ? keyword : queryParams?.search,
            start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
            end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
        });
    };

    const getList = async () => {
        setLoading(true);
        const requestBody = {
            ...queryParams,
        };

        const [err, result] = await catchAsync(announcementApi.getListAnnouncement(requestBody), setLoading);
        if (err) {
            toast.error(err);
            return;
        }
        const { items, pagination } = result?.data;
        setRows(items);
        setPagination(pagination);
    };

    const handleChangePage = (page: any) => {
        setQueryParams({
            ...queryParams,
            page: page + 1,
        });
    };

    const handleChangePicker = (values: any[] = []) => {
        setDateSearch((_prev: any) => values);
    };

    const resetData = () => {
        clearQueryParams();
        setKeyword("");
        setFormatSearch({
            label: t("All Type"),
            value: "0",
        });
        setDateSearch([moment().subtract(7, "days").toDate(), moment().toDate()]);
    };

    const handleConfirmNotice = () => {
        showModal(
            {
                message: (
                    <div>
                        <div style={{fontWeight:'600'}}>{t('announcement.confirm_delete_1')} </div>
                        {t('announcement.confirm_delete_2')}
                    </div>
                ),
                submitButtonText: "확인",
                width: "497px",
            },
            async () => {
                return handleDeleteNotice();
            }
        );
    };

    const handleDeleteNotice = async () => {
        const callApi = announcementApi.deleteAnnouncements(selectedIds);
        const [error, result] = await catchAsync(
            callApi, setLoading
        );
        if (error) {
            toast.error(error)
            return;
        }
        setSelectedIds([]);
        setSelectAll(false);
        getList();
    }

    useEffect(() => {
        const allSelected = selectedIds?.length === rows?.length && rows?.length > 0;
        setSelectAll(allSelected);
    }, [selectedIds, rows]);

    useEffect(() => {
        getList();
        if (queryParams?.search) {
            setKeyword(queryParams.search as string);
        }
    }, [location?.search]);

    useEffect(() => {
        // document.title = `Super Admin`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            <div className='page-content'>
                <Container fluid>
                    <BreadCrumb title={t("announcement.menu_title")} />
                    <div className='d-flex align-items-center gap-2 mb-3 justify-content-between'>
                        <div></div>
                        <button
                            type='button'
                            className='btn btn-primary me-1'
                            onClick={() => setIsOpenPopup(true)}
                        >
                            {`${t("announcement.register_notice")} `}
                        </button>
                    </div>
                    <Row>
                        <Col lg={12}>
                            <Card id='leadsList'>
                                <CardHeader className='border-0'>
                                    <div className='d-flex flex-column flex-md-row g-4 align-items-start align-items-lg-end mb-2'>
                                        <div className='w-100'>
                                            <div className='align-items-end'>
                                                <Row>
                                                    <Col sm={12} md={12} lg={6} xl={6} className='mt-2 mt-md-0 mb-2 mb-md-0'>
                                                        <div
                                                            className='d-flex align-items-center date-picker-wrapper-custom'
                                                            style={{ gap: "40px" }}
                                                        >
                                                            <div className='fw-bold text-nowrap'>{t("inquiry.period")}</div>
                                                            <div style={{ width: "300px" }}>
                                                                <DatePickerCustom
                                                                    placeholder={`${t("A From Range")} - ${t("A To Range")}`}
                                                                    startDate={startDate || null}
                                                                    endDate={endDate || null}
                                                                    disabled={false}
                                                                    isClearable={true}
                                                                    onChangePicker={handleChangePicker}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className='g-4 align-items-center mt-0 mt-md-2'>
                                                    <Col sm={12} md={12} lg={4} className='mt-3 mt-md-2'>
                                                        <div className='d-flex align-items-center ' style={{ gap: "40px" }}>
                                                            <div className='fw-bold text-nowrap'>{t("inquiry.search")}</div>
                                                            <Input
                                                                type='text'
                                                                className='form-control search'
                                                                placeholder={`${t("announcement.placeholder_search")}`}
                                                                value={keyword}
                                                                onChange={(e) => {
                                                                    setIsChangeKeyword(true);
                                                                    setKeyword(e.target.value);
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === "Enter") {
                                                                        searchData();
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col
                                                        sm={12}
                                                        md={12}
                                                        lg={3}
                                                        className='hstack gap-1 justify-content-sm-center justify-content-md-between mt-sm-4 mt-md-2'
                                                    >
                                                        <div>
                                                            <button
                                                                type='button'
                                                                className='btn btn-primary me-1'
                                                                onClick={searchData}
                                                                disabled={loading}
                                                            >
                                                                <i className='ri-search-line align-bottom me-1'></i>
                                                                {t("Button Search")}
                                                            </button>
                                                            <button
                                                                type='button'
                                                                className='btn btn-secondary fs-14'
                                                                onClick={resetData}
                                                            >
                                                                <i className='ri-refresh-line align-bottom me-1'></i>
                                                                {t("Button Reset")}
                                                            </button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='d-flex justify-content-between'>
                                        <Nav
                                            className='nav-tabs-custom rounded card-header-tabs border-bottom-0 mt-0'
                                            role='tablist'
                                        >
                                            {SERVICE_TYPE_OPTIONS_LANG.map((item, index) => (
                                                <NavItem key={index}>
                                                    <NavLink
                                                        className={`cursor-pointer ${classnames({
                                                            active: formatSearch?.value === item?.value,
                                                        })}`}
                                                        onClick={() => {
                                                            setFormatSearch(item);
                                                            setQueryParams({
                                                                ...queryParams,
                                                                exposure_type: item?.value,
                                                            });
                                                        }}
                                                    >
                                                        {t(item?.label)}
                                                    </NavLink>
                                                </NavItem>
                                            ))}
                                        </Nav>
                                        <button
                                            type='button'
                                            className='btn btn-primary me-1'
                                            disabled={!selectedIds?.length}
                                            onClick={handleConfirmNotice}
                                        >
                                            {t("announcement.delete_notice")}
                                        </button>
                                    </div>
                                </CardHeader>
                                <CardBody className='pt-3'>
                                    <div>
                                        <TableContainer
                                            className='custom-header-css'
                                            divClass='table-responsive table-card'
                                            tableClass='align-middle text-center'
                                            theadClass='table-light text-center'
                                            columns={columns}
                                            data={rows || []}
                                            customPageSize={pagination?.page_size}
                                            customPageIndex={pagination?.page - 1}
                                            totalRecords={pagination?.total}
                                            customPageCount={Math.ceil(pagination?.total / pagination?.page_size)}
                                            handleChangePage={handleChangePage}
                                            onClickRow={(_,row) => {
                                                setDetail(row?.original);
                                                setIsOpenPopup(true);
                                            }}
                                            manualSorting={true}
                                            isLoading={loading}
                                        />
                                    </div>
                                    <ToastContainer closeButton={false} limit={1} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ModalAnnouncement open={isOpenPopup} data={detail} onClose={() => {
                setIsOpenPopup(false);
                setDetail(null);
            }} onSubmit={() => {
                getList();
                setIsOpenPopup(false);
                setDetail(null);
            }} />
        </React.Fragment>
    );
};

export default AnnouncementList;

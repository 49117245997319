import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import { Col, Container, Row, Spinner } from "reactstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { catchAsync } from "helpers/general";
import accountApi from "api/accountApi";
import { removeEmptyFields } from "../../utils/index";
import { toast, ToastContainer } from "react-toastify";
import { useModal } from "context/confirm.context";

registerLocale("en", en);
registerLocale("ko", ko);

const CreateAndUpdateAccount = () => {
  const { t, i18n } = useTranslation();
  let [searchParams] = useSearchParams();
  const userID = searchParams.get("userID");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [listSelected, setListSelected] = useState<string[]>([]);
  const { showModal } = useModal();

  const permissions = [
    {
      id: 0,
      name: t("account.select_all"),
      permissions: [
        {
          id: "media_management",
          name: "media_management",
        },
        {
          id: "article_management",
          name: "article_management",
        },
        {
          id: "customer_inquiry_management",
          name: "customer_inquiry_management",
        },
        {
          id: "cms_hub",
          name: "cms_hub",
        },
        {
          id: "sales_management",
          name: "sales_management",
        },
        {
          id: "announcement",
          name: "announcement",
        }
      ],
    },
  ];

  const permissionsUnused = [
    {
      permissions: [
        {
          id: "settlement_management",
          name: "settlement_management",
          disabled: true,
        },
        {
          id: "pr_hub",
          name: "pr_hub",
          disabled: true,
        },
      ],
    },
  ];

  const validationSchema = Yup.object({
    email: Yup.string().required("").email(t("account.InvalidEmailFormatError")),
    name: Yup.string().required(""),
    permissions: Yup.array().min(1, "").required(""),
  });

  const handleSubmit = async (values: any) => {
    const requestBody = { ...values };
    delete requestBody?.password;
    let response: any;
    if (userID) {
      response = await catchAsync(accountApi.updateAccount(userID, requestBody), setLoading);
    } else {
      response = await catchAsync(
        accountApi.createAccount(removeEmptyFields(requestBody)),
        setLoading
      );
    }
    if (response?.[0]) {
      toast(response?.[0]?.errors?.email || response?.[0]?.message);
      if (response?.[0]?.errors) formik.setErrors(response?.[0]?.errors);
      return;
    }
    navigate("/account");
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      password: "123456789",
      note: "",
      permissions: [],
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleCheckPermission = (
    event: any,
    sub1: number | null = null,
    sub2: number | null = null,
    sub3: number | null = null
  ) => {
    const isChecked = event.target.checked;

    if (sub2 !== null && sub1 !== null) {
      const val: string = ((permissions as any) || [])[sub1]?.permissions[sub2]?.id;
      setListSelected((prev) => {
        return isChecked ? [...prev, val] : prev?.filter((item) => ![val]?.includes(item));
      });
      return;
    }
    if (sub1 !== null) {
      const val: string[] =
        ((permissions as any) || [])[sub1]?.permissions?.map((item: any) => item?.id) || [];
      setListSelected((prev) => {
        return isChecked ? [...prev, ...val] : prev?.filter((item) => !val?.includes(item));
      });
      return;
    }
    if (sub1 === null) {
      const val: string[] = ((permissions as any) || [])
        ?.map((sub: any) => {
          return sub?.permissions?.map((item: any) => item?.id) || [];
        })
        .flat();
      setListSelected((prev) => {
        return isChecked ? [...prev, ...val] : [];
      });
      return;
    }
  };

  const isChecked = (
    sub: string,
    sub1: number | null = null,
    sub2: number | null = null,
    sub3: number | null = null
  ) => {
    if (sub === "SUB2" && sub2 !== null && sub1 !== null) {
      const val: string = ((permissions as any) || [])[sub1]?.permissions[sub2]?.id;
      return [val]?.reduce((result: boolean, item: string) => {
        return result && listSelected?.includes(item);
      }, true);
    }
    if (sub === "SUB1" && sub1 !== null) {
      const val: string[] =
        ((permissions as any) || [])[sub1]?.permissions?.map((item: any) => item?.id) || [];
      return val?.reduce((result: boolean, item: string) => {
        return result && listSelected?.includes(item);
      }, true);
    }
    if (sub === "SUB0") {
      const val: string[] = ((permissions as any) || [])
        ?.map((sub: any) => {
          return sub?.permissions?.map((item: any) => item?.id) || [];
        })
        .flat();
      return val?.reduce((result: boolean, item: string) => {
        return result && listSelected?.includes(item);
      }, true);
    }
    return false;
  };

  const handleGetUser = async (userID: string) => {
    const [err, result] = await catchAsync(accountApi.getUserByID(userID));
    if (err) {
      console.log(err);
      return navigate("/account");
    }
    formik.setValues(result?.data);
    setListSelected(result?.data?.permissions);
  };

  const handleResetPassword = async () => {
    if (!userID) return;
    const [err] = await catchAsync(accountApi.resetPassword(userID, {}));
    if (err) {
      console.log(err);
      return;
    }
    navigate("/account");
  };

  useEffect(() => {
    const selectedPermissions = Array.from(new Set(listSelected));
    formik.setFieldValue("permissions", selectedPermissions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listSelected]);

  useEffect(() => {
    // document.title = `${t("Create Update")} - ${t("Account")} | SuperAdmin`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    if (!userID) return;
    handleGetUser(userID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID]);

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} limit={1} />
      <div className='page-content'>
        <Container fluid>
          <div className='d-flex align-items-center gap-2 mb-3'>
            <button
              onClick={() => navigate(-1)}
              type='button'
              className='btn btn-icon btn-topbar btn-ghost-secondary rounded-circle'
            >
              <i className={"bx bx-arrow-back fs-22"}></i>
            </button>
            <h4 className='mb-sm-0'>
              {userID ? t("account.account_details") : t("account.add_account")}
            </h4>
          </div>
          <div>
            <form onSubmit={formik.handleSubmit}>
              <Row className='row g-5'>
                <Col xxl={4}>
                  <Row className='row g-3'>
                    <Col xxl={12}>
                      <label className='form-label'>
                        {t("account.email")}
                        <span className='text-danger'> *</span>
                      </label>
                      <input
                        className='form-control'
                        id='email'
                        name='email'
                        value={formik?.values?.email}
                        placeholder={t("account.email_placeholder")}
                        onChange={(event: any) =>
                          formik.setFieldValue("email", event?.target?.value || "")
                        }
                        maxLength={50}
                        disabled={!!userID}
                      />
                      {formik.errors.email && (
                        <div className='text-danger mt-2'>{formik.errors.email}</div>
                      )}
                    </Col>
                    <Col xxl={12}>
                      <label className='form-label'>
                        {t("account.name")}
                        <span className='text-danger'> *</span>
                      </label>
                      <input
                        className='form-control'
                        id='name'
                        name='name'
                        value={formik?.values?.name}
                        placeholder={t("account.name_placeholder")}
                        onChange={(event: any) =>
                          formik.setFieldValue("name", event?.target?.value || "")
                        }
                        maxLength={10}
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <div className='text-danger mt-2'>{formik.errors.name}</div>
                      ) : null}
                    </Col>
                    <Col xxl={12}>
                      <label className='form-label'>{t("account.password")}</label>
                      {userID ? (
                        <div>
                          <button
                            className='btn btn-primary fs-14'
                            type='button'
                            onClick={() => {
                              showModal(
                                {
                                  message: (
                                    <div>
                                      <p> {t("account.would_you_like_to_reset_your_password")}</p>
                                    </div>
                                  ),
                                  title: t("account.confirm"),
                                  submitButtonText: t("account.btn.check"),
                                  cancelButtonText: t("account.btn.cancel"),
                                },
                                async () => {
                                  handleResetPassword();
                                }
                              );
                            }}
                          >
                            {t("account.btn.reset_password")}
                          </button>
                          <p className='fs-12 mt-2'>{t("account.init_password")}</p>
                        </div>
                      ) : (
                        <input
                          className='form-control'
                          id='password'
                          name='password'
                          value={formik?.values?.password}
                          // placeholder={`${t("Media Name")}...`}
                          onChange={(event: any) =>
                            formik.setFieldValue("password", event?.target?.value || "")
                          }
                          disabled
                        />
                      )}
                    </Col>
                    <Col xxl={12}>
                      <label className='form-label'>{t("account.note")}</label>
                      <textarea
                        className='form-control'
                        id='note'
                        name='note'
                        value={formik?.values?.note || ""}
                        rows={6}
                        placeholder={t("account.note_placeholder")}
                        maxLength={500}
                        onChange={(event: any) =>
                          formik.setFieldValue("note", event?.target?.value || "")
                        }
                      />
                      {formik.touched.note && formik.errors.note ? (
                        <div className='text-danger mt-2'>{formik.errors.note}</div>
                      ) : null}
                    </Col>
                  </Row>
                </Col>
                <Col xxl={8}>
                  <label className='form-label'>
                    {t("account.authority")}
                    <span className='text-danger'> *</span>
                  </label>
                  <Row
                    className='row g-3 d-flex justify-content-start'
                    style={{
                      paddingLeft: "12px",
                    }}
                  >
                    <Col xxl={8}>
                      {permissions?.map((item: any, sub1: number) => (
                        <div key={item?.id}>
                          <div
                            className='form-check form-check-primary notification-check mb-2'
                            style={{
                              paddingLeft: "10px",
                            }}
                          >
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value=''
                              id={`check-${sub1}`}
                              checked={isChecked("SUB1", sub1)}
                              onChange={(e) => handleCheckPermission(e, sub1)}
                            />
                            <label className='form-check-label' htmlFor={`check-${sub1}`}>
                              {t(`${item?.name || ""}`)}
                            </label>
                          </div>
                          <div>
                            <Row>
                              {item?.permissions?.map((child: any, sub2: number) => (
                                <Col xxl={6} className='my-1 px-0 mx-0' key={sub2}>
                                 
                                  <div key={child?.id}>
                                    <div className='form-check form-check-primary notification-check ms-0'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id={`check-${sub1}-${sub2}`}
                                        checked={isChecked("SUB2", sub1, sub2)}
                                        onChange={(e) => handleCheckPermission(e, sub1, sub2)}
                                        disabled={child?.disabled}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor={`check-${sub1}-${sub2}`}
                                      >
                                        {t(`account.permissions.${child?.name || ""}`)}
                                      </label>
                                    </div>
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          </div>
                        </div>
                      ))}
                      {permissionsUnused?.map((item: any, sub1: number) => (
                        <div key={item?.id}>
                          <Row>
                            {item?.permissions?.map((child: any, sub2: number) => (
                              <Col xxl={6} className='my-1 px-0 mx-0' key={sub2} style={{pointerEvents:'none'}}>
                                <div key={child?.id}>
                                  <div className='form-check form-check-primary notification-check ms-0'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value=''
                                      id={`check-${sub1}-${sub2}`}
                                      // checked={isChecked("SUB2", sub1, sub2)}
                                      // onChange={(e) => handleCheckPermission(e, sub1, sub2)}
                                      disabled={child?.disabled}
                                    />
                                    <label
                                      className='form-check-label'
                                      htmlFor={`check-${sub1}-${sub2}`}
                                    >
                                      {t(`account.permissions.${child?.name || ""}`)}
                                    </label>
                                  </div>
                                </div>
                              </Col>
                            ))}
                          </Row>
                        </div>
                      ))}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className='d-flex justify-content-center mt-5 gap-3'>
                <button
                  className='btn btn-light fs-14 ms-4'
                  type='button'
                  onClick={() => navigate("/account")}
                >
                  {t("account.btn.cancel")}
                </button>
                <button
                  type='submit'
                  className='btn btn-primary fs-14'
                  disabled={loading || !formik.dirty || !formik.isValid}
                >
                  {loading && (
                    <Spinner size='sm' className='me-2'>
                      {t("Loading")}
                    </Spinner>
                  )}
                  {userID ? t("account.btn.save") : t("account.btn.add")}
                </button>
              </div>
            </form>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateAndUpdateAccount;

import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import {
  INQUIRY_STATUS,
  TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS,
} from "helpers/constans";
import moment from "moment";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Container, Label, Spinner } from "reactstrap";
import { catchAsync } from "helpers/general";
import { toast, ToastContainer } from "react-toastify";
import inquiryApi from "api/inquiryApi";
import MediaApi from "api/mediaApi";
import iconFile from "../../assets/images/icon-file.svg";
import photoSvg from "../../assets/images/inquiry/photo.svg";
import { useModal } from "context/confirm.context";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import MediaModal from "pages/Media/Media/ModalMedia";
import ModalConfirmPoint from "./ModalConfirmPoint";
import Lightbox from "yet-another-react-lightbox";
import FileViewer from "components/Common/fileViewer";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

export interface Tag {
  id: string;
  text: string;
}

registerLocale("en", en);
registerLocale("ko", ko);

const AcceptFileType = [
  "image/jpg",
  "application/pdf",
  "image/png",
  "image/jpeg",
];

const ServiceInquiryDetail = (asd: any) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { showModal } = useModal();
  const user: any = useSelector((state: any) => state.Login);
  const [data, setData] = useState(location?.state);
  const [loadingMedia, setLoadingMedia] = useState(false);
  const [choseFile, setChoseFile] = useState<any[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [index, setIndex] = useState<any>(-1);
  const [media, setMedia] = useState<any>();
  const [loadingReply, setLoadingReply] = useState<boolean>(false);
  const [textReply, setTextReply] = useState<string>("");
  const [indexReply, setIndexReply] = useState<any>(-1);
  const [isDeductPoints, setIsDeductPoints] = useState<boolean>(false);
  const [deductPoints, setDeductPoints] = useState<string | number>("0");
  const [modalPoint, setModalPoint] = useState<boolean>(false);

  const slideGalleryReply = useMemo(() => {
    return choseFile;
  }, [choseFile]);

  const slideGallery = useMemo(() => {
    return data?.inquiry_files?.map((item: any) => ({
      src: item.path,
    }));
  }, [data?.inquiry_files]);

  const changeStatusToWaiting = async (id: any) => {
    const [err] = await catchAsync(inquiryApi.updateStatusWaiting(id));
    if (err) {
      toast.error(err);
      return;
    }
    getInquiryDetail(id);
  };

  const changeStatusToInProgress = (id: any) => async () => {
    const [err] = await catchAsync(inquiryApi.updateStatusInProgress(id));
    if (err) {
      toast.error(err);
      return;
    }
    getInquiryDetail(id);
  };

  const handleGetMedia = async (id: string) => {
    const [err, result] = await catchAsync(
      MediaApi.getMediaByID(id),
      setLoadingMedia
    );
    setMedia(result.data);

    if (err) {
      toast.error(err);
      return;
    }
  };

  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (
      event?.target?.files === null ||
      event.target?.files?.length === 0 ||
      event?.target?.files?.length > 5 ||
      event?.target?.files?.length + choseFile.length > 5 ||
      !AcceptFileType.includes(event?.target?.files?.[0]?.type)
    ) {
      return;
    }
    const files = event?.target?.files?.[0]?.type !== "application/pdf" ? [{ src: URL.createObjectURL(event.target.files[0]), type: "image" ,file:event?.target?.files?.[0]}]: [{ src: URL.createObjectURL(event.target.files[0]),file:event?.target?.files?.[0] }];
    setChoseFile([...choseFile, ...(files as any)]);
    setTimeout(() => ((event.target as HTMLInputElement).value = ""), 100);
  };

  const handleRemoveImage = (index: number) => {
    const imageTmp = structuredClone(choseFile);
    imageTmp.splice(index, 1);
    setChoseFile(imageTmp);
  };

  const renderStatus = (statusValue: string | number) => {
    const statusOptions = new Map(
      INQUIRY_STATUS.map((i) => [i.value, i.label])
    );
    return t(statusOptions.get(statusValue?.toString()) || "-");
  };

  const getInquiryDetail = async (id: any) => {
    const [err, result] = await catchAsync(inquiryApi.getDetail(id, {}));
    if (err) {
      toast.error(err);
      return;
    }
    setData(result?.data);
    setTextReply(result?.data?.reply || "");
    const photos: { src: string; type?: string | undefined }[] = [];
    result?.data?.inquiry_reply_files.forEach(
      (element: { id: number; path: string }) => {
        if(element?.path?.split(".")?.pop()?.toLowerCase() === "pdf"){
          photos.push({
            src: element?.path,
          });
        } else {
          photos.push({
            src: element?.path,
            type: "image"
          });
        }
      }
    );
    setChoseFile(photos);
  };

  const onCloseClick = () => {
    // setData({});
    const photos: { src: string; type?: string | undefined }[] = [];
    data?.inquiry_reply_files.forEach(
      (element: { id: number; path: string }) => {
        if(element?.path?.split(".")?.pop()?.toLowerCase() === "pdf"){
          photos.push({
            src: element?.path,
          });
        } else {
          photos.push({
            src: element?.path,
            type: "image"
          });
        }
      }
    );
    setChoseFile(photos);
    setTextReply("");
    setIsEdit(false);
    setIsDeductPoints(false);
    setDeductPoints("0");
  };

  const submitReply = async (newRequest?: {
    reply?: string;
    files?: any;
    deduction_point?: string | number;
  }) => {
    if (!data?.id) {
      setLoadingReply(false);
      return;
    }
    const formData = new FormData();
    formData.append("reply", textReply);

    const deduction_point: any =
      newRequest?.deduction_point == 0 || newRequest?.deduction_point
        ? newRequest?.deduction_point
        : deductPoints || 0;

    formData.append("deduction_point", deduction_point);

    if (choseFile?.length) {
      choseFile.forEach((i) => {
        formData.append("files[]", i?.file || i?.src || "");
      });
    } else {
      formData.append("files[]", "");
    }

    const [err, result] = await catchAsync(
      inquiryApi.updateReply({
        id: data?.id,
        body: formData,
      }),
      setLoadingReply
    );

    if (result?.statusCode === 200) {
      toast.success(t("inquiry.reply_toast_message"), {
        position: "top-center",
      });
      getInquiryDetail(id);
      setDeductPoints(deduction_point);
      setIsEdit(false);
    }

    if (err === "406") {
      setModalPoint(true);
      return;
    }

    if (err) {
      toast.error(err);
      return;
    }
  };

  useEffect(() => {
    if (id) {
      getInquiryDetail(id);
    }
  }, [id]);

  useEffect(() => {
    // document.title = `Super Admin`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="d-flex align-items-center gap-2 mb-3">
            <button
              onClick={() => navigate(-1)}
              type="button"
              className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
            >
              <i className={"bx bx-arrow-back fs-22"}></i>
            </button>
            <h4 className="mb-sm-0">
              {t("inquiry.menu_title")} | {t("inquiry.menu_detail")}
            </h4>

            {/* <div style={{ marginLeft: "auto" }} className="d-flex gap-3">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setOpen(true)}
              >
                {t("inquiry.btn.edit_info")}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setModalConfirm(true)}
              >
                {t("inquiry.btn.delete")}
              </button>
            </div> */}
          </div>
          <Card id="leadsList">
            <CardBody className="pt-3">
              <div>
                {/* <div className="d-flex gap-4 align-items-center py-1">
                  <Label
                    className="form-label mb-0"
                    style={{ minWidth: "60px" }}
                  >
                    {t("inquiry.Service")}
                  </Label>
                  <div
                    className="mb-0"
                    style={{
                      width: "fit-content",
                    }}
                  >
                    {renderService(data?.service_type || "")}
                  </div>
                </div> */}
                <div className="">
                  <div className="d-flex gap-4 align-items-center">
                    <Label
                      className="form-label mb-0"
                      style={{ minWidth: "100px" }}
                    >
                      {t("inquiry.Created_At")}
                    </Label>
                    <p className="mb-0">
                      {moment(data?.created_at).isValid()
                        ? moment(data?.created_at).format("YYYY.MM.DD HH:mm:ss")
                        : "-"}
                    </p>
                  </div>
                  <div className="d-flex gap-4 align-items-center mt-2">
                    <Label
                      className="form-label mb-0"
                      style={{ minWidth: "100px" }}
                    >
                      {t("inquiry.Inquiry_type")}
                    </Label>
                    <p className="mb-0">{data?.inquiry_category?.name}</p>
                  </div>
                  <div className="d-flex gap-1 align-items-center">
                    <div className="d-flex gap-4 align-items-center mt-2">
                      <Label
                        className="form-label mb-0"
                        style={{ minWidth: "100px" }}
                      >
                        {t("inquiry.Author")}
                      </Label>
                      <p
                        className="mb-0"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!loadingMedia) {
                            handleGetMedia(data?.company?.id);
                          }
                        }}
                        onDoubleClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {data?.created_by?.name}
                      </p>
                    </div>
                    <Spinner
                      size={"sm"}
                      style={{
                        visibility: loadingMedia ? "visible" : "hidden",
                      }}
                    />
                  </div>

                  <div className="d-flex gap-4 align-items-center mt-2">
                    <Label
                      className="form-label mb-0"
                      style={{ minWidth: "100px" }}
                    >
                      {t("inquiry.Status")}
                    </Label>
                    <p className="mb-0">{renderStatus(data?.status)}</p>
                  </div>

                  {/* <div className="d-flex gap-4 align-items-center mt-2">
                    <Label
                      className="form-label mb-0"
                      style={{ minWidth: "100px" }}
                    >
                      {t("inquiry.points_held")}
                    </Label>
                    <p className="mb-0">{data?.total_point}</p>
                  </div> */}
                </div>
                <div className="mb-2">
                  <div className="mt-2 py-1">
                    <div className="d-flex gap-5 align-items-center">
                      <Label className="form-label">
                        {t("inquiry.detail")}
                      </Label>

                      <Label
                        className="form-label"
                        style={{ minWidth: "100px" }}
                      >
                        {t("inquiry.points_held")}
                      </Label>
                      <p className="form-label">{data?.total_point}</p>
                    </div>
                    {data?.inquiry_category?.code === "domain_management" ? (
                      <>
                        <div>
                          <p>
                            {t("inquiry.FORM.DOMAIN.LABEL")}:{" "}
                            {data?.details?.domain_purchaser}
                          </p>
                          <p>
                            {t("inquiry.FORM.ID.LABEL")}:{" "}
                            {data?.details?.identity}
                          </p>
                          <p>
                            {t("inquiry.FORM.PASSWORD.LABEL")}:{" "}
                            {data?.details?.password}
                          </p>
                          <p>
                            {t("inquiry.FORM.CONTACT_NUMBER.LABEL")}:{" "}
                            {data?.details?.manager}
                          </p>
                          <p>
                            {t("inquiry.FORM.PHONE.LABEL")}:{" "}
                            {data?.details?.phone_number}
                          </p>
                          <p
                            style={{
                              wordBreak: "break-all",
                            }}
                          >
                            {t("inquiry.FORM.DETAIL.LABEL")}:{" "}
                            {data?.description}
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <p
                          className="mb-0"
                          style={{
                            wordBreak: "break-all",
                            maxHeight: "200px",
                            overflow: "auto",
                          }}
                        >
                          {data?.description}
                        </p>
                      </>
                    )}
                  </div>

                  {data?.inquiry_files?.length > 0 && (
                    <div className="d-flex gap-2 my-4">
                      {data?.inquiry_files?.map(
                        (file: { id: number; path: string }, index: number) => (
                          <div
                            key={file?.id}
                            className="d-flex flex-col align-items-center justify--content-center gap-1 py-2"
                            style={{
                              width: "60px",
                              height: "60px",
                            }}
                          >
                            <img
                              src={
                                file.path?.split(".")?.pop()?.toLowerCase() ===
                                "pdf"
                                  ? iconFile
                                  : file.path
                              }
                              alt="inquiry"
                              style={{
                                width: "60px",
                                height: "60px",
                                cursor: "pointer",
                              }}
                              onClick={() => setIndex(index)}
                            />
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
                <div
                  className=""
                  style={{
                    visibility:
                      data?.status == "1" || data?.status == "2"
                        ? "visible"
                        : "hidden",
                  }}
                >
                  <div className="d-flex align-items-center gap-5">
                    <Label className="form-label">
                      {t("inquiry.customer_answer")}
                    </Label>
                    {data?.status == "2" && (
                      <div className="d-flex gap-5 form-label">
                        <div className="d-flex gap-4 align-items-center">
                          <Label
                            className="form-label mb-0"
                            style={{ minWidth: "60px" }}
                          >
                            {t("inquiry.CS_Manager")}
                          </Label>
                          <p className="mb-0">{data?.managed_by?.name}</p>
                        </div>
                        <div className="d-flex gap-4 align-items-center">
                          <Label
                            className="form-label mb-0"
                            style={{ minWidth: "60px" }}
                          >
                            {t("inquiry.Deduction_points")}
                          </Label>
                          <p className="mb-0">{data?.deduction_point}</p>
                        </div>
                        <div className="d-flex gap-4 align-items-center">
                          <Label
                            className="form-label mb-0"
                            style={{ minWidth: "60px" }}
                          >
                            {t("inquiry.reply_at")}
                          </Label>
                          <p className="mb-0">
                            {moment(data?.reply_at).isValid()
                              ? moment(data?.reply_at).format(
                                  "YYYY.MM.DD HH:mm:ss"
                                )
                              : "-"}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <textarea
                    className="form-control"
                    id="reply"
                    rows={5}
                    placeholder={t("inquiry.PlaceholderMemo")}
                    value={textReply}
                    onChange={(e: any) => setTextReply(e.target.value)}
                    disabled={!isEdit && data?.status == "2"}
                  />

                  <div className="d-flex gap-2 my-4">
                    {choseFile.length > 0 &&
                      choseFile.map((file: any, index: number) => (
                        <div
                          key={index}
                          className="d-flex flex-col align-items-center justify--content-center gap-1 position-relative"
                          style={{
                            width: "60px",
                            height: "60px",
                            borderRadius: "50%",
                          }}
                        >
                          <img
                            src={file?.type === "image"
                              ? file?.src
                              :  iconFile
                            }
                            onClick={() => setIndexReply(index)}
                            alt="inquiry"
                            style={{
                              width: "60px",
                              height: "60px",
                              cursor: "pointer",
                            }}
                          />
                          {((isEdit && data?.status == "2") ||
                            data?.status == "1") && (
                            <button
                              onClick={() => handleRemoveImage(index)}
                              type="button"
                              className="btn-close position-absolute bg-white"
                              aria-label="delete"
                              style={{
                                top: "1px",
                                right: "1px",
                                width: "5px",
                                height: "5px",
                                borderRadius: 0,
                              }}
                            ></button>
                          )}
                        </div>
                      ))}
                    {choseFile.length < 5 &&
                      ((isEdit && data?.status == "2") ||
                        data?.status == "1") && (
                        <div>
                          <label
                            htmlFor="file-upload"
                            className="d-flex flex-column align-items-center justify-content-center border border-dark"
                            style={{
                              width: "60px",
                              height: "60px",
                              cursor:
                                !isEdit && data?.status == "2"
                                  ? "auto"
                                  : "pointer",
                            }}
                          >
                            <img
                              src={photoSvg}
                              alt="upload_photo"
                              width={24}
                              height={24}
                            />
                            <p className="text-[#5F646F] fs-12 mb-0">
                              {choseFile.length}/5
                            </p>
                          </label>

                          <input
                            id="file-upload"
                            type="file"
                            className="d-none"
                            accept={AcceptFileType.toString()}
                            multiple
                            onChange={handleUploadFile}
                            disabled={!isEdit && data?.status == "2"}
                          />
                        </div>
                      )}
                  </div>
                  {((isEdit && data?.status == "2") || data?.status == "1") && (
                    <div className="w-50 mx-auto">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checkbox_deduction_point"
                          id="checkbox_deduction_point"
                          checked={isDeductPoints}
                          onChange={(e) => {
                            setIsDeductPoints(e.target.checked);
                            if (!e.target.checked) {
                              setDeductPoints(0);
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="checkbox_deduction_point"
                        >
                          포인트 차감하기
                        </label>
                      </div>
                      <div className="position-relative w-100">
                        <input
                          autoFocus
                          className="form-control"
                          id="deduction_point"
                          name="deduction_point"
                          value={deductPoints}
                          disabled={!isDeductPoints}
                          // defaultValue={"0"}
                          onChange={(event: any) => {
                            if (
                              /^[0-9]*$/.test(event?.target?.value) ||
                              event?.target?.value === ""
                            ) {
                              let value = event?.target?.value;
                              if (value.charAt(0) === "0") {
                                value = value.substring(1);
                              }
                              setDeductPoints(value);
                            }
                          }}
                          style={{
                            textAlign: "right",
                            paddingRight: "25px",
                          }}
                          maxLength={9}
                        />
                        <p
                          className="position-absolute"
                          style={{
                            top: 8,
                            right: 10,
                          }}
                        >
                          pt
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="hstack gap-2 mt-3 justify-content-center">
                  {data?.status == "0" && (
                    <button
                      className="btn btn-primary fs-14 w-25"
                      onClick={changeStatusToInProgress(id)}
                    >
                      {t("inquiry.btn.reply")}
                    </button>
                  )}
                  {data?.status == "1" && (
                    <>
                      <button
                        type="button"
                        className="btn btn-secondary fs-14"
                        onClick={() => {
                          showModal(
                            {
                              message: (
                                <div>
                                  <p className="mb-2">
                                    {t(
                                      "inquiry.If_you_cancel_your_registration_the_status_of_your_inquiry_will_change_to_Awaiting_Response"
                                    )}
                                  </p>
                                  <p>
                                    {" "}
                                    {t(
                                      "inquiry.Do_you_want_to_cancel_your_registration"
                                    )}
                                  </p>
                                </div>
                              ),
                              title: t("inquiry.confirm"),
                              submitButtonText: t("inquiry.btn.confirm"),
                              cancelButtonText: t("inquiry.btn.cancel"),
                            },
                            async () => {
                              changeStatusToWaiting(id);
                            }
                          );
                        }}
                      >
                        {t("inquiry.btn.cancel_registration")}
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary fs-14"
                        onClick={() => submitReply()}
                      >
                        {t("inquiry.btn.submit_response")}
                      </button>
                    </>
                  )}
                  {data?.status == "2" && (
                    <>
                      {isEdit ? (
                        <>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={onCloseClick}
                          >
                            {t("inquiry.btn.cancel")}
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary fs-14"
                            onClick={() => submitReply()}
                          >
                            {loadingReply ? (
                              <Spinner size="sm"></Spinner>
                            ) : null}
                            {t("inquiry.btn.save")}
                          </button>
                        </>
                      ) : (
                        data?.managed_by?.id === user?.user?.admin?.id && (
                          <button
                            type="submit"
                            className="btn btn-primary fs-14"
                            onClick={() => {
                              setIsEdit(true);
                              if (data?.deduction_point > 0) {
                                setIsDeductPoints(true);
                                setDeductPoints(data?.deduction_point);
                              }
                            }}
                            disabled={
                              data?.managed_by?.id !== user?.user?.admin?.id
                            }
                          >
                            {t("inquiry.btn.edit")}
                          </button>
                        )
                      )}
                    </>
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        </Container>
        <ToastContainer closeButton={false} limit={1} />
        <MediaModal
          media={media}
          onClose={() => setMedia(false)}
          onRefresh={() => {
            console.log("onRefresh");
          }}
          mode="view"
        />
        <ModalConfirmPoint
          open={modalPoint}
          onSubmit={() => {
            setModalPoint(false);
            submitReply({
              deduction_point: 0,
            });
          }}
          onClose={() => setModalPoint(false)}
        />
        <Lightbox
          index={index}
          slides={slideGallery}
          open={index >= 0}
          close={() => setIndex(-1)}
          plugins={[Fullscreen, Zoom]}
          render={{
            slide: ({ slide }: any) => {
              const isImage = /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(slide?.src || "");
              if (isImage) return null;
              return (
                <FileViewer
                  width={"100%"}
                  height={"100%"}
                  src={slide?.src}
                  objectFit="none"
                />
              );
            },
          }}
          zoom={{
            maxZoomPixelRatio: 5,
          }}
        />
        <Lightbox
          index={indexReply}
          slides={slideGalleryReply}
          open={indexReply >= 0}
          close={() => setIndexReply(-1)}
          plugins={[Fullscreen, Zoom]}
          render={{
            slide: ({ slide }: any) => {
              const isImage = ["image"].includes(slide?.type || "");
              if (isImage) return null;
              return (
                <iframe
                  src={slide?.src}
                  title='PDF'
                  style={{
                    width:'100wh',
                    height:'100vh'
                  }}
                />
              );
            },
          }}
          noScroll={{
            disabled:true
          }}
          zoom={{
            maxZoomPixelRatio: 5,
          }}
        />
        <ToastContainer closeButton={false} limit={1} />
      </div>
    </React.Fragment>
  );
};

export default ServiceInquiryDetail;
